import { Button, MenuItem, Paper, Typography, useTheme, Box, Modal, TextField, Grid, Alert, AlertTitle, InputAdornment } from "@mui/material"
import React, { useState, useEffect } from 'react'
import { deleteByIdRequest, findByIdRequest, saveEmployeeRequset, updateEmployeeRequest } from "../../api/controllers/employee-controller"
import CustomDataGrid, { IslemlerRender } from "../../components/CustomDataGrid"
import NumberFormat from 'react-number-format';
import { toast } from "react-toastify";
import CustomDialog from "../../components/CustomDialog";
import { findAllEmployeesForAdminRequest } from "../../api/controllers/admin-controller";
import { SearchRounded } from "@mui/icons-material";

const Page = () => {
    const theme = useTheme()

    const [search, setSearch] = useState("")
    const [employees, setEmployees] = useState([])
    const [employeesBackUp, setEmployeesBackUp] = useState([])
    const [loading, setLoading] = useState(true)
    const [pageable, setPageable] = useState({ page: 0, size: 100, total: 0 });
    const [editModalOpen, setEditModalOpen] = useState(false)
    const defaultEditEmployeeForm = {
        id: null,
        createdDateTime: "",
        firstName: "",
        lastName: "",
        phone: "",
        province: null,
        email: "",
        password: "",
        rePassword: "",
    }
    const [editEmployeeForm, setEditEmployeeForm] = useState(defaultEditEmployeeForm)

    const columns = [
        {
            field: 'firstName',
            headerName: 'Adı',
            minWidth: 130,
            flex: 1,

        },
        {
            field: 'lastName',
            headerName: 'Soyadı',
            minWidth: 130,
            flex: 1,
        },
        {
            field: 'email',
            headerName: 'Email',
            minWidth: 130,
            flex: 1,
        },
        {
            field: 'phone',
            headerName: 'Telefon',
            minWidth: 130,
            flex: 1,
        },
        {
            field: 'id',
            headerName: "",
            sortable: false,
            align: 'right',
            renderCell: ({ value, row }) => <IslemlerRender
                menuItems={
                    [
                        <MenuItem key={"edit"} onClick={() => {
                            let id = Number((value))
                            handleEditOpenModal(id)
                        }}>Düzenle</MenuItem>,
                        /*  <MenuItem key={"edit"} onClick={async () => {
                             await setForm(row);
                             setOpenModal(true);
                         }}>Düzenle</MenuItem>, */
                        /* <MenuItem key={"delete"} onClick={async () => {
                            let id = Number((value));
                            await deleteByIdRequest({ id });
                            window.location.reload();
                        }
                        }>Sil</MenuItem> */
                    ]
                }
            />
        },
    ]

    const fetchEmployee = async () => {
        setLoading(true);
        try {
            let res = await findAllEmployeesForAdminRequest(pageable)
            if (res?.data) {
                setEmployees(res?.data?.content)
                setEmployeesBackUp(res?.data?.content)
                setPageable({ ...pageable, total: res.data.totalElements });
            }

        } catch (error) { }
        setLoading(false);
    }

    const handleEditOpenModal = async (id) => {
        try {
            let res = await findByIdRequest({ id })
            if (res) {
                setEditEmployeeForm(res.data)
                setEditModalOpen(true)
            }
        } catch (error) { console.log('error', error) }
    }

    const closeModal = () => {
        setEditModalOpen(false);
        setEditEmployeeForm(defaultEditEmployeeForm);
    }

    const handleCreate = async (e) => {
        e.preventDefault()
        if (editEmployeeForm.password !== editEmployeeForm.rePassword) {
            toast.warning("Parolalar uyuşmuyor")
            return
        }
        try {
            let res = await saveEmployeeRequset(editEmployeeForm)
            if (res) {
                closeModal();
                fetchEmployee();
            }
        } catch (error) { }
    }

    const handleUpdate = async (e) => {
        e.preventDefault()
        try {
            let res = await updateEmployeeRequest(editEmployeeForm)
            if (res) {
                closeModal();
                fetchEmployee();
            }
        } catch (error) { }
    }

    useEffect(() => {
        fetchEmployee()

    }, [])

    useEffect(() => {
        if (search) {
            let abc = employeesBackUp.filter((v) => v.firstName.toUpperCase().includes(search.toUpperCase()) || v.lastName.toUpperCase().includes(search.toUpperCase()) || v.email.toUpperCase().includes(search.toUpperCase()) || v.phone.toUpperCase().includes(search.toUpperCase()))
            setEmployees(abc)
        } else {
            fetchEmployee()
        }
    }, [search])

    return (
        <Box p={3}>
            <Paper
                elevation={0}
                sx={{
                    display: "flex",
                    padding: theme.spacing(2),
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: "row" },
                    background: "#FBE9E7",
                    boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.15)"
                }}
            >
                <Typography variant="h5" color="primary" fontWeight={500}>Kullanıcılar</Typography>
                <div
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: "row" }
                    }}
                >
                    {/* <Button onClick={() => {setOpenModal(true) }} disableElevation sx={{
                        margin: theme.spacing(1),
                        width: { xs: "100%", sm: "100%", md: "auto", lg: "auto" }
                    }} variant="contained" color="primary">
                    Yeni Kullanıcı Ekle
                    </Button> */}
                </div>

            </Paper>
            <Paper
                elevation={0}
                sx={{
                    padding: theme.spacing(2),
                    marginTop: theme.spacing(2),
                    minHeight: "50vh",
                }}
            >
                <TextField
                    variant="standard"
                    placeholder="Kullanıcılar içinde arayın"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchRounded />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        width: "100%",
                        maxWidth: "220px",
                        marginBottom: theme.spacing(2)
                    }}
                    value={search}
                    onChange={(e) => { setSearch(e.target.value) }}

                />
                <CustomDataGrid
                    columns={columns}
                    data={employees}
                    loading={loading}
                    pageable={pageable}
                    setPageable={setPageable}
                />
            </Paper>

            <CustomDialog
                open={editModalOpen}
                onClose={closeModal}
                title={editEmployeeForm?.id ? "Düzenle" : "Oluştur"}
                onSubmit={editEmployeeForm?.id ? handleUpdate : handleCreate}
                buttons={[
                    {
                        text: "Kaydet",
                        props: {
                            variant: "contained",
                            type: "submit",
                        }
                    }
                ]}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="outlined"
                            label="Adı"
                            fullWidth
                            required={true}
                            value={editEmployeeForm?.firstName}
                            onChange={(e) => setEditEmployeeForm(prev => ({ ...prev, firstName: e.target.value }))}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="outlined"
                            label="Soyadı"
                            fullWidth
                            required={true}
                            value={editEmployeeForm?.lastName}
                            onChange={(e) => setEditEmployeeForm(prev => ({ ...prev, lastName: e.target.value }))}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <NumberFormat
                            format="+90 (###) ### ## ##"
                            value={editEmployeeForm?.phone.replace("+90", "")}
                            onValueChange={({ value }) => setEditEmployeeForm(prev => ({ ...prev, phone: `+90${value}` }))}
                            customInput={TextField}
                            variant="outlined"
                            fullWidth
                            label="Telefon Numaranız"
                            required
                            name="telefonNumarasi"
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="outlined"
                            label="Email"
                            fullWidth
                            required={true}
                            type="email"
                            value={editEmployeeForm?.email}
                            onChange={(e) => setEditEmployeeForm(prev => ({ ...prev, email: e.target.value }))}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="outlined"
                            label="Parola"
                            fullWidth
                            required={editEmployeeForm?.id ? false : true}
                            type="password"
                            value={editEmployeeForm?.password}
                            onChange={(e) => setEditEmployeeForm(prev => ({ ...prev, password: e.target.value }))}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="outlined"
                            label="Parola Tekrarı"
                            fullWidth
                            required={editEmployeeForm?.id ? false : true}
                            type="password"
                            value={editEmployeeForm?.rePassword}
                            onChange={(e) => setEditEmployeeForm(prev => ({ ...prev, rePassword: e.target.value }))}
                            autoComplete="off"
                        />
                    </Grid>
                </Grid>
            </CustomDialog>

        </Box>
    )

}

export default Page