import axios from "axios";

export const meRequest = () => axios.get(`/account`)

export const forgotPasswordSendEmailRequest = ({ email }) => axios.post(`/account/forgotPassword/sendMail`, { email })

export const forgotPasswordSendSmsRequest = ({ phone }) => axios.post(`/account/forgotPassword/sendSms`, { phone })

export const loginRequest = async ({ password, phoneNumber, rememberMe }) => axios.post(`/account/login`, {
    password, phoneNumber, rememberMe
})

export const logoutRequest = () => axios.post(`/account/logout`);


export const registerCorporateRequest = async ({ email, password, phone, province, title }) => axios.post("/account/register/corporate", {
    email, password, phone, province, title
})

export const registerIndividualRequest = async ({ email, firstName, lastName, phone, password, province }) => axios.post("/account/register/individual", {
    email, firstName, lastName, phone, password, province
})

export const resetPasswordRequest = ({ newPassword, verificationCode }) => axios.post(`/account/resetPassword`, { newPassword, verificationCode })

export const updateIndividualUser = ({ firstName, lastName, province, }) => axios.put(`/account/update/individual`, { firstName, lastName, province })

export const updateCorporateUser = ({ province, title }) => axios.put(`/account/update/corporate`, { province, title })

export const changePhoneRequest = ({ newPhone }) => axios.post(`/account/changePhone`, { newPhone })

export const changeEmailRequest = ({ newEmail }) => axios.post(`/account/changeEmail`, { newEmail })

export const verifyPhoneCodeRequest = ({ code }) => axios.get(`/account/verifyPhoneCode?code=${code}`)

export const verifyEmailCodeRequest = ({ code }) => axios.get(`/account/verifyEmailCode?code=${code}`)

export const changePasswordRequest = ({ newPassword, oldPassword, repeatNewPassword }) => axios.put(`/account/changePassword`, { newPassword, oldPassword, repeatNewPassword })

export const getMyApiKeyRequest = () => axios.get(`/account/getMyApiKey`)

export const createMyApiKeyRequest = () => axios.post(`/account/createApiKey`)
