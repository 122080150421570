import { DataGrid, GRID_DEFAULT_LOCALE_TEXT, GridToolbarExport, GridFooter } from '@mui/x-data-grid'
import { IconButton, Menu, Box, useTheme, TextField, Grid } from "@mui/material"
import { Clear, MoreVert, Search } from "@mui/icons-material"
import { useState } from 'react'



let trLocale = GRID_DEFAULT_LOCALE_TEXT
trLocale.noRowsLabel = "Sonuç Bulunamadı"
trLocale.noResultsOverlayLabel = "Sonuç Bulunamadı"
trLocale.filterPanelColumns = "Sütunlar"
trLocale.filterPanelOperators = "Operatörler"
trLocale.filterPanelInputLabel = "Ara"
trLocale.filterPanelInputPlaceholder = "Tabloda arama yapabilirsiniz"
trLocale.toolbarExportLabel = "Çıktı Al"
trLocale.toolbarExport = "Çıktı Al"
trLocale.toolbarExportCSV = "Csv Olarak Çıktı Al"
trLocale.footerRowSelected = (number) => `${number} Satır Seçili.`






export const IslemlerRender = ({ menuItems }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };



    return (
        <div>
            <IconButton onClick={handleClick}>
                <MoreVert />
            </IconButton>
            <Menu
                id="islemler-positioned-menu"
                aria-labelledby="islemler-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {menuItems}
            </Menu>
        </div>
    )
}




const CustomDataGrid = ({ data, columns, CustomFooterComponent, loading, pageable, setPageable }) => {
    return (
        <div style={{ display: 'flex', minHeight: '100%', width: "inherit" }}>
            <div style={{ flexGrow: 1, width: "inherit" }}>
                <DataGrid
                    rows={data}
                    columns={columns}
                    checkboxSelection
                    disableSelectionOnClick
                    disableColumnMenu
                    loading={loading}
                    pageSize={25}
                    filterMode="client"
                    localeText={trLocale}
                    components={{
                        Footer: () => <Grid
                            container
                            sx={{
                                flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}
                        >
                            {CustomFooterComponent || <div></div>}
                            <GridFooter />

                        </Grid>,
                    }}
                    pagination
                    paginationMode={pageable ? "server" : "client"}
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    rowCount={pageable && pageable.total}
                    page={pageable && pageable.page}
                    onPageChange={val => pageable && !loading && setPageable({ ...pageable, page: val })}
                    pageSize={pageable && pageable.size}
                    onPageSizeChange={val => pageable && !loading && setPageable({ ...pageable, size: val })}

                    style={{
                        width: "inherit",
                        minHeight: "65vh"
                    }}
                />
            </div>
        </div>
    )


}



export default CustomDataGrid