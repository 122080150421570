import React from 'react'

const SuspenseFallback = () => {
    return (
        <div style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <object width="250" type="image/svg+xml" data="/assets/Suspense.svg" />
        </div>
    )
}

export default SuspenseFallback