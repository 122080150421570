import axios from "axios";

export const saveVehicleRequest = ({
    fuelType,
    idleAlarmSecond,
    imei,
    licensePlate,
    maintenanceDistance,
    maxSpeed,
    safeZoneId,
    totalDistance,
    vehicleName
}) => axios.post(`/vehicle`, {
    fuelType,
    idleAlarmSecond,
    imei,
    licensePlate,
    maintenanceDistance,
    maxSpeed,
    safeZoneId,
    totalDistance,
    vehicleName
})

export const vehicleAssignSafeZoneRequest = ({ safeZoneId, vehicleId }) => axios.post(`/vehicle/assign/safeZone`, {
    safeZoneId, vehicleId
})

export const findAllVehiclesRequest = () => axios.get(`/vehicle`)

export const findAllVehiclesPageableRequest = (search, pageable) => axios.get(`/vehicle/pageable?page=${pageable.page}&size=${pageable.size}`)

export const findByIdRequest = ({ id }) => axios.get(`/vehicle/${id}`)

export const updateStatusRequest = ({ id, vehicleStatus }) => axios.get(`/vehicle/${id}/status`, vehicleStatus)

export const setLockRequest = ({ id, value }) => axios.put(`/vehicle/${id}/lock?value=${value}`, { value })

export const updateVehicleRequest = (form) => axios.put(`/vehicle/${form.id}`, form)

export const deleteByIdRequest = ({ id }) => axios.delete(`/vehicle/${id}`)
