import axios from "axios";

export const saveEmployeeRequset = (form) => axios.post(`/employee`, form)

export const updateEmployeeRequest = (form) => axios.put(`/employee/${form.id}`, form)

export const deleteByIdRequest = ({ id }) => axios.delete(`/employee/${id}`)

export const findByIdRequest = ({ id }) => axios.get(`/employee/${id}`)

export const findAllEmployeesRequest = () => axios.get(`/employee`)
