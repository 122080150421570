import { CssBaseline } from "@mui/material"
import CustomThemeProvider from "./CustomThemeProvider"
import Router from "./Router"
import React from 'react'
import ApiProvider from "../api/ApiProvider"
import FirebaseProvider from "./FirebaseProvider"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'moment/locale/tr'
import { LocalizationProvider } from "@mui/lab"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const App = () => {
  return (
    <CustomThemeProvider>
      <ApiProvider>
        <FirebaseProvider>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <CssBaseline />
            <Router />
          </LocalizationProvider>
          <ToastContainer position="bottom-left" autoClose={1500} />
        </FirebaseProvider>
      </ApiProvider>
    </CustomThemeProvider>
  )
}

export default App
