import React, { useEffect, useState } from 'react'
import { useTheme, Paper, Typography, Box, List, ListItem, ListItemText, Switch } from "@mui/material"
import { getSettingsInfoRequest, idleNotificationRequest, ignitionFalseNotificationRequest, ignitionTrueNotificationRequest, logChangesWhenIgnitionFalseNotificationRequest, overSpeedRequest, safeZoneOutNotificationRequest } from '../../api/controllers/setting-controllers';
import { getMyApiKeyRequest } from '../../api/controllers/account-controller';

const Page = () => {
    const theme = useTheme();
    const [settings, setSettings] = useState(null);

    const getSettings = async () => {
        let res = await getSettingsInfoRequest();
        if (res.data) {
            setSettings(res.data);
        }
    }

 

    useEffect(() => {
        getSettings();
    }, []);

    const CustomListItem = (props) => (
        <ListItem sx={{
            borderRadius: ".5rem",
            ":hover": {
                bgcolor: "rgba(207,86,21,0.1)",
                color: "rgb(207,86,21)",
            },
        }}>
            <ListItemText primary={props.text} />
            <Switch
                edge="end"
                onChange={props.onChange}
                checked={props.checked}
            />
        </ListItem>
    )

    return (
        <Box p={3}>
            <Paper
                elevation={0}
                sx={{
                    padding: theme.spacing(2),
                    background: "#FBE9E7",
                    boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.15)"
                }}
            >
                <Typography
                    variant="h5"
                    color="primary"
                    fontWeight={500}
                >
                    Ayarlar
                </Typography>
            </Paper>

            <Paper
                elevation={0}
                sx={{
                    padding: "1rem",
                    marginTop: theme.spacing(2),
                    minHeight: "50vh",
                }}
            >
                {
                    settings &&
                    <List sx={{ width: "100%" }}>
                        <CustomListItem
                            text="Kontak açıldığında bildirim al"
                            onChange={() => ignitionTrueNotificationRequest().then(() => getSettings())}
                            checked={settings.ignitionTrueNotification}
                        />
                        <CustomListItem
                            text="Kontak kapandığında bildirim al"
                            onChange={() => ignitionFalseNotificationRequest().then(() => getSettings())}
                            checked={settings.ignitionFalseNotification}
                        />
                        <CustomListItem
                            text="Güvenli bölge dışına çıkıldığında bildirim al"
                            onChange={() => safeZoneOutNotificationRequest().then(() => getSettings())}
                            checked={settings.safeZoneOutNotification}
                        />
                        <CustomListItem
                            text="Hız limiti aşıldığında bildirim al"
                            onChange={() => overSpeedRequest().then(() => getSettings())}
                            checked={settings.overSpeed}
                        />
                        <CustomListItem
                            text="Kontak kapalıyken konum değiştiğinde bildirim al"
                            onChange={() => logChangesWhenIgnitionFalseNotificationRequest().then(() => getSettings())}
                            checked={settings.logChangesWhenIgnitionFalseNotification}
                        />
                        <CustomListItem
                            text="Rölanti bildirimi al"
                            onChange={() => idleNotificationRequest().then(() => getSettings())}
                            checked={settings.idleNotification}
                        />
                    </List>
                }
            </Paper>
        </Box>
    )

}

export default Page