import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

/**
 * suspense olursa menu kayıyor.
 */
// const kullanici_ayarlar = lazy(() => import('./pages/kullanici/ayarlar'));
// const kullanici_hesabim = lazy(() => import('./pages/kullanici/hesabim'));
// const kullanici_kullanicilar = lazy(() => import('./pages/kullanici/kullanicilar'));
import araclar from '../pages/admin/araclar'
import kullanicilar from '../pages/admin/kullanicilar'
import kullanilmis_imeiler from '../pages/admin/kullanilmis_imeiler'
import kullanici_ayarlar from '../pages/kullanici/ayarlar'
import kullanici_hesabim from '../pages/kullanici/hesabim'
import SuspenseFallback from './SuspenseFallback';
import PrivateRoute from './PrivateRoute';

const not_found = lazy(() => import('../pages/NotFound'));
const develop = lazy(() => import('../pages/develop'));
const cikis = lazy(() => import('../pages/cikis_yap'));
const index = lazy(() => import('../pages/index'));
const login = lazy(() => import('../pages/login'));
const register = lazy(() => import('../pages/register'));
const forgot_password = lazy(() => import('../pages/forgot-password'));
const reset_password = lazy(() => import('../pages/reset-password'));
const dashboard = lazy(() => import('../pages/dashboard'));

const tanimlar_araclar = lazy(() => import('../pages/tanimlar/araclar'));
const tanimlar_kullanicilar = lazy(() => import('../pages/tanimlar/kullanicilar'));
const tanimlar_gruplar = lazy(() => import('../pages/tanimlar/gruplar'));
const tanimlar_guvenli_bolgeler = lazy(() => import('../pages/tanimlar/guvenli_bolgeler'));

const islemler_harita = lazy(() => import('../pages/islemler/harita'));
const islemler_takip = lazy(() => import('../pages/islemler/takip'));
const islemler_gecmis_takip = lazy(() => import('../pages/islemler/gecmis_takip'));

const raporlar_hareket_raporu = lazy(() => import('../pages/raporlar/hareket_raporu'));
const raporlar_hareket_raporu_sonuc = lazy(() => import('../pages/raporlar/alarmlar'));
const raporlar_bitmis_arizalar = lazy(() => import('../pages/raporlar/bitmis_arizalar'));
const raporlar_bitmis_bakimlar = lazy(() => import('../pages/raporlar/bitmis_bakimlar'));
const raporlar_aylik_bakim_raporu = lazy(() => import('../pages/raporlar/aylik_bakim_raporu'));
const raporlar_aylik_malzeme_raporu = lazy(() => import('../pages/raporlar/aylik_malzeme_raporu'));

const Router = () => {

    return (
        <BrowserRouter>
            <Suspense fallback={<SuspenseFallback />}>
                <Switch>

                    <Route exact path="/" component={index} />
                    <Route exact path="/Login" component={login} />
                    <Route exact path="/register" component={register} />
                    <Route exact path="/forgot-password" component={forgot_password} />
                    <Route exact path="/reset-password" component={reset_password} />
                    <PrivateRoute exact path="/develop" component={develop} />

                    <PrivateRoute exact path="/app/admin/araclar" component={araclar} />
                    <PrivateRoute exact path="/app/admin/kullanicilar" component={kullanicilar} />
                    <PrivateRoute exact path="/app/admin/kullanilmis-imeiler" component={kullanilmis_imeiler} />

                    <PrivateRoute exact path="/app/kullanici/ayarlar" component={kullanici_ayarlar} />
                    <PrivateRoute exact path="/app/kullanici/hesabim" component={kullanici_hesabim} />

                    <PrivateRoute exact path="/app/tanimlar/araclar" component={tanimlar_araclar} />
                    <PrivateRoute exact path="/app/tanimlar/guvenli-bolgeler" component={tanimlar_guvenli_bolgeler} />
                    <PrivateRoute exact path="/app/tanimlar/kullanicilar" component={tanimlar_kullanicilar} />
                    <PrivateRoute exact path="/app/tanimlar/gruplar" component={tanimlar_gruplar} />

                    <PrivateRoute exact path="/app/islemler/harita" component={islemler_harita} />
                    <PrivateRoute exact path="/app/islemler/takip" component={islemler_takip} />
                    <PrivateRoute exact path="/app/islemler/gecmis_takip" component={islemler_gecmis_takip} />

                    <PrivateRoute exact path="/app/raporlar/hareket-raporu" component={raporlar_hareket_raporu} />
                    <PrivateRoute exact path="/app/raporlar/alarmlar" component={raporlar_hareket_raporu_sonuc} />
                    <PrivateRoute exact path="/app/raporlar/bitmis-arizalar" component={raporlar_bitmis_arizalar} />
                    <PrivateRoute exact path="/app/raporlar/bitmis-bakimlar" component={raporlar_bitmis_bakimlar} />
                    <PrivateRoute exact path="/app/raporlar/aylik-bakim-raporu" component={raporlar_aylik_bakim_raporu} />
                    <PrivateRoute exact path="/app/raporlar/aylik-malzeme-raporu" component={raporlar_aylik_malzeme_raporu} />
                    <PrivateRoute exact path="/app/logout" component={cikis} />


                    <PrivateRoute exact path="/app" component={dashboard} />

                    <Route component={not_found} />

                </Switch>
            </Suspense>
        </BrowserRouter>
    )

}

export default Router

