import { CloseRounded } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton } from '@mui/material'
import React from 'react'

function CustomDialog({ children, buttons, title, open, onClose, onSubmit, maxWidth }) {
    return (
        <Dialog
            maxWidth={maxWidth || "sm"}
            open={open}
            onClose={onClose}
            PaperComponent={onSubmit ? "form" : "div"}
            PaperProps={{ sx: { bgcolor: "white", borderRadius: "1rem" }, onSubmit: onSubmit && onSubmit }}
            scroll="paper"
        >
            <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                {title}
                <IconButton onClick={onClose}>
                    <CloseRounded />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ paddingTop: ".5rem" }}>
                    {
                        children
                    }
                </DialogContentText>
            </DialogContent>
            {
                buttons?.length > 0 &&
                <>
                    <Divider />
                    <DialogActions sx={{ padding: "1rem", display: "flex", gap: "0.5rem" }}>
                        {
                            buttons.map((v, i) =>
                                <LoadingButton key={i} {...v.props}>
                                    {v.text}
                                </LoadingButton>
                            )
                        }
                    </DialogActions>
                </>
            }
        </Dialog>
    )
}

export default CustomDialog