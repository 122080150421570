import axios from "axios"
import { useMemo } from "react"
import { toast } from "react-toastify"

export const BASE_URL = process.env.REACT_APP_API_URL;
//export const BASE_URL = "http://localhost:8088";

axios.defaults.baseURL = `${BASE_URL}/api/v1`

const ApiProvider = (props) => useMemo(() => {
    axios.interceptors.request.use(function (config) {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    });

    axios.interceptors.response.use(function (response) {
        if (response.data.token) {
            localStorage.setItem("token", response.data.token);
        }
        return response;
    }, function (error) {
        if (error.config && error.response) {
            if (error.response.status === 400) {
                if (Array.isArray(error.response.data.data)) {
                    toast.error(error.response.data.data[0].message);
                } else if (error.response.data) {
                    toast.error(error?.response?.data.message);
                } else {
                    toast.error("Bir hata meydana geldi! Daha sonra tekrar deneyin.");
                }
            } else if (error.response.status === 401) {
                toast.warn("Giriş yapmak için yönlendiriliyorsunuz!", {
                    onClose: () => window.location.replace("/login")
                });
            } else if (error.response.status === 403) {
                toast.error("Bu işlem için yetkiniz bulunmamaktadır!");

            } else if (error.response.status === 503 || error.response.status === 0) {
                toast.error("Sunucuya ulaşılamıyor! Lütfen daha sonra tekrar deneyin.");
            } else {
                toast.error("Bir hata meydana geldi! Sistem yöneticinize başvurun!");
            }
        }

        return Promise.reject(error);
    });

    return props.children;
}, [])

export default ApiProvider;