import { Button, MenuItem, Paper, Typography, useTheme, Box, Modal, TextField, Grid, Alert, AlertTitle, InputAdornment } from "@mui/material"
import React, { useState, useEffect } from 'react'
import { deleteByIdRequest, findByIdRequest, saveEmployeeRequset, updateEmployeeRequest } from "../../api/controllers/employee-controller"
import CustomDataGrid, { IslemlerRender } from "../../components/CustomDataGrid"
import NumberFormat from 'react-number-format';
import { toast } from "react-toastify";
import CustomDialog from "../../components/CustomDialog";
import { findAllEmployeesForAdminRequest } from "../../api/controllers/admin-controller";
import { SearchRounded } from "@mui/icons-material";
import { deleteByIdImeiRequest, getAllImeisRequest } from "../../api/controllers/used-imei-controller";

const Page = () => {
    const theme = useTheme()

    const [usedImeis, setUsedImeis] = useState([])
    const [usedImeisBackUp, setUsedImeisBackUp] = useState([])
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState("")

    const columns = [
        {
            field: 'imei',
            headerName: 'IMEI',
            minWidth: 130,
            flex: 1,
        },
        {
            field: 'id',
            headerName: "",
            sortable: false,
            align: 'right',
            renderCell: ({ value }) => <IslemlerRender
                menuItems={
                    [
                        /*  <MenuItem key={"edit"} onClick={() => {
                             let id = Number((value))
                             handleEditOpenModal(id)
                         }}>Düzenle</MenuItem>, */
                        /*  <MenuItem key={"edit"} onClick={() => {
                             let id = Number((value))
                             handleEditOpenModal(id)
                         }}>Düzenle</MenuItem>,
                         */
                        <MenuItem key={"delete"} onClick={async () => {
                            const confirm = await window.confirm("Silmek istediğinize emin misiniz?")
                            if (confirm) {
                                let id = Number((value))
                                try {
                                    await deleteByIdImeiRequest(id)
                                } catch (error) {
                                    console.log('error', error)
                                }
                                fetchUsedImei()
                            }
                        }
                        }>Sil</MenuItem>
                    ]
                }
            />
        },
    ]

    const fetchUsedImei = async () => {
        setLoading(true);
        try {
            let res = await getAllImeisRequest()
            if (res?.data) {
                setUsedImeis(res?.data)
                setUsedImeisBackUp(res?.data)
            }

        } catch (error) { }
        setLoading(false);
    }

    useEffect(() => {
        fetchUsedImei()

    }, [])

    useEffect(() => {
        if (search) {
            let abc = usedImeisBackUp.filter((v) => v.imei.toUpperCase().includes(search.toUpperCase()))
            setUsedImeis(abc)
        } else {
            fetchUsedImei()
        }
    }, [search])

    return (
        <Box p={3}>
            <Paper
                elevation={0}
                sx={{
                    display: "flex",
                    padding: theme.spacing(2),
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: "row" },
                    background: "#FBE9E7",
                    boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.15)"
                }}
            >
                <Typography variant="h5" color="primary" fontWeight={500}>Kullanılmış Imei'ler</Typography>
                <div
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: "row" }
                    }}
                >
                    {/* <Button onClick={() => {setOpenModal(true) }} disableElevation sx={{
                        margin: theme.spacing(1),
                        width: { xs: "100%", sm: "100%", md: "auto", lg: "auto" }
                    }} variant="contained" color="primary">
                    Yeni Kullanıcı Ekle
                    </Button> */}
                </div>

            </Paper>
            <Paper
                elevation={0}
                sx={{
                    padding: theme.spacing(2),
                    marginTop: theme.spacing(2),
                    minHeight: "50vh",
                }}
            >
                <TextField
                    variant="standard"
                    placeholder="Kullanılmış imei'ler içinde arayın"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchRounded />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        width: "100%",
                        maxWidth: "220px",
                        marginBottom: theme.spacing(2)
                    }}
                    value={search}
                    onChange={(e) => { setSearch(e.target.value) }}

                />
                <CustomDataGrid
                    columns={columns}
                    data={usedImeis}
                    loading={loading}
                /* pageable={pageable}
                setPageable={setPageable} */
                />
            </Paper>

        </Box>
    )

}

export default Page