import { AppBar, Button, Chip, Grid, IconButton, Toolbar, useTheme, Fade, Typography, Box, MenuItem, ListItemIcon, ListItemText, Menu, List, ListItem, ListItemAvatar, Avatar, Alert, Popper, ListSubheader, Divider, Popover } from "@mui/material"
import { NotificationsRounded, ExpandMore, AccountCircle, AccountBox, Settings, ExitToApp, Menu as MenuIcon, FormatListBulletedRounded } from "@mui/icons-material"
import { useState, useEffect } from "react"
import { Link, useLocation, useHistory } from "react-router-dom"
import { meRequest, logoutRequest } from "../api/controllers/account-controller"
import { getMyNotificationsRequest } from "../api/controllers/notification-controller"
import InfiniteScroll from "react-infinite-scroll-component"
import moment from "moment";

const CustomLayout = ({ children }) => {

    const theme = useTheme()

    const location = useLocation()
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [displayName, setDisplayName] = useState("")
    const [userType, setUserType] = useState("")
    const history = useHistory()
    const [data, setData] = useState("")





    const fetchAccountInformation = async () => {

        try {
            let res = await meRequest()
            if (res?.data) {
                setData(res?.data)
                if (res?.data?.userType === "CorporateUser") {
                    setDisplayName(res?.data?.title)

                }
                else if (res?.data?.userType === "IndividualUser") {
                    setDisplayName(res?.data?.firstName)
                }
            }
        }
        catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        (
            async () => {
                await fetchAccountInformation()
            }
        )()

        return () => {

        }
    }, [])



    const NotificationBar = () => {
        const [notifications, setNotifications] = useState([]);
        const [anchorEl, setAnchorEl] = useState(null);
        const [size, setSize] = useState(5)
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const open = Boolean(anchorEl);

        const getNotifications = async () => {
            try {
                let res = await getMyNotificationsRequest(size);
                if (res) {
                    setNotifications(res.data.content);
                }
            } catch (error) { }
        }

        useEffect(() => {
            if (open) {
                getNotifications();
            }
        }, [open, size])

        return (
            <>
                <IconButton
                    onClick={handleClick}
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        borderRadius: "4px",
                        "&:hover": {
                            color: theme.palette.primary.main,
                            backgroundColor: theme.palette.primary.contrastText,
                        }
                    }}
                    color="primary"
                    variant="outlined"
                >
                    <NotificationsRounded />
                </IconButton>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <List
                        sx={{
                            width: 360,
                            bgcolor: 'background.paper',
                            position: 'relative',
                            overflow: 'auto',
                            maxHeight: 300,
                            zIndex: 9999,
                            '& ul': { padding: 0 },
                        }}
                        component="nav"
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                Bildirimler
                            </ListSubheader>
                        }
                        dense={true}
                    >
                        <Divider sx={{ mb: "0.5rem" }} />
                        {
                            notifications.map((v, i) =>
                                <ListItem key={i}>
                                    <ListItemAvatar>
                                        <Avatar sx={{ bgColor: v.isOpened ? "#bdbdbd" : "#FBE9E6" }}>
                                            <NotificationsRounded fontSize="small" />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<b>{v.title}</b>}
                                        secondary={<div><p style={{ margin: 0 }}>{v.body}</p> <p style={{ margin: 0, fontSize: 12, fontWeight: 600, opacity: 0.7 }}>{moment(v.createdDateTime).fromNow()}</p></div>}
                                    />
                                </ListItem>,
                            )
                        }
                        {
                            notifications.length === size &&
                            <ListItem onClick={() => setSize(prev => prev + 20)} >
                                <Typography sx={{ width: "100%", textAlign: "center", cursor: "pointer" }}>Daha fazla getir</Typography>
                            </ListItem>
                        }
                        {
                            notifications.length < 1 &&
                            <ListItem>
                                <Alert severity="success" sx={{ width: "100%" }}>
                                    Bildirim yok
                                </Alert>
                            </ListItem>
                        }
                    </List>
                </Popover>
            </>
        )
    }


    const CustomMenu = ({ items, openIcon, closedIcon, icon, expandable = false, link = "#", title, blinking }) => {

        const [menuOpen, setMenuOpen] = useState(false)
        const [anchorEl, setAnchorEl] = useState(null)
        const [isOpen, setIsOpen] = useState(false)

        function showIcon() {
            if (icon) {
                return icon;
            } else {
                if (isOpen) {
                    return openIcon;
                } else {
                    return closedIcon;
                }
            }
        }

        useEffect(() => {

            if (location?.pathname === link) {
                setIsOpen(true)
            } else if (String(location?.pathname).includes(link) && link !== "/app") {
                setIsOpen(true)
            } else {
                setIsOpen(false)
            }
            return () => {

            }
        }, [])


        return (
            <Grid item sx={{
                height: { xs: "9vh", sm: "9vh", md: "9vh", lg: "9vh" },
                margin: {
                    xs: theme.spacing(0, 1),
                    sm: theme.spacing(0, 0.5),
                },
                display: "flex",
                alignItems: "center"
            }}>
                {
                    !expandable && link
                        ? <Link to={link}>
                            <Button className="NavIconButton"
                                disableElevation
                                onClick={(e) => {
                                    if (!expandable) {
                                        return
                                    }
                                    setAnchorEl(e.currentTarget)
                                    setMenuOpen(true);
                                }}
                                startIcon={showIcon()}
                                variant={"contained"}
                                sx={{
                                    paddingX: { xs: "16", md: "11px", lg: "16" },
                                    minHeight: "40px",
                                    backgroundColor: !isOpen && theme.palette.grey[200],
                                    color: !isOpen && theme.palette.primary.main,
                                    "&:hover": {
                                        backgroundColor: !isOpen && theme.palette.grey[400],
                                        color: !isOpen && theme.palette.primary.main,
                                    }
                                }}
                                color={isOpen ? "primary" : "secondary"}
                                endIcon={expandable && <ExpandMore />}
                            >
                                {title}

                                {
                                    blinking &&
                                    <Typography
                                        variant="body2"
                                        className="blink"
                                        sx={{
                                            textTransform: "none",
                                            padding: theme.spacing(0, 1),
                                            margin: theme.spacing(0, 1),
                                            borderRadius: "5px"
                                        }}
                                    >
                                        Canlı
                                    </Typography>
                                }
                            </Button>
                        </Link>
                        : <Button
                            disableElevation
                            onClick={(e) => {
                                if (!expandable) {
                                    return
                                }
                                setAnchorEl(e.currentTarget)
                                setMenuOpen(true);
                            }}
                            startIcon={showIcon()}
                            variant={"contained"}
                            sx={{
                                paddingX: { xs: "0px", md: "16px" },
                                minHeight: "40px",
                                backgroundColor: !isOpen && theme.palette.grey[200],
                                color: !isOpen && theme.palette.primary.main,
                                "&:hover": {
                                    backgroundColor: !isOpen && theme.palette.grey[400],
                                    color: !isOpen && theme.palette.primary.main,
                                }
                            }}

                            color={isOpen ? "primary" : "secondary"}
                            endIcon={expandable && <ExpandMore />}
                        >
                            {title}
                        </Button>

                }

                <Menu
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onClose={() => {
                        setMenuOpen(false)
                        setAnchorEl(null)
                    }}
                    TransitionComponent={Fade}
                    sx={{
                        overflow: "hidden"
                    }}
                    PaperProps={{
                        elevation: 3,
                        style: {
                            width: "auto",
                            minWidth: anchorEl?.offsetWidth || "auto",
                        }
                    }}
                >
                    {
                        items && items.map((item, i) => <Link key={i} to={item?.link}>
                            <MenuItem>
                                {item?.icon &&
                                    <ListItemIcon>
                                        {item?.icon}
                                    </ListItemIcon>
                                }

                                <ListItemText>{item?.text}</ListItemText>
                            </MenuItem>
                        </Link>)
                    }

                </Menu>
            </Grid>
        )
    }

    return (
        <Box className="NavIconButton">
            <AppBar sx={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.common.black
            }}
                elevation={1}
                position="static"
            >
                <Toolbar >
                    <Grid container justifyContent="space-between" alignItems="center" flexDirection="row" >
                        <Grid item >
                            <a href="/app">
                                <img src="/assets/logo.svg" style={{ height: "auto", width: "100%" }} />
                            </a>
                        </Grid>
                        <Grid item
                            sx={{
                                flexGrow: {
                                    xs: 1,
                                    md: 1,
                                },
                                display: { md: "none", xs: "flex" }
                            }}
                        >
                            <Grid container
                                justifyContent={{
                                    xs: "center",

                                }}
                                alignItems="center"
                            >
                                <NotificationBar />
                                <CustomMenu

                                    icon={<AccountCircle />}
                                    expandable
                                    items={[
                                        {
                                            text: "Hesabım",
                                            link: "/app/kullanici/hesabim",
                                            icon: <AccountBox fontSize="small" />
                                        },
                                        {
                                            text: "Ayarlar",
                                            link: "/app/kullanici/ayarlar",
                                            icon: <Settings fontSize="small" />
                                        },
                                        {
                                            text: "Çıkış Yap",
                                            link: "/app/logout",
                                            icon: <ExitToApp fontSize="small" />
                                        },
                                    ]}
                                />
                            </Grid>
                        </Grid>
                        <Grid item sx={{
                            display: {
                                xs: "flex",
                                sm: "none"
                            },
                            justifyContent: {
                                sm: "flex-end",
                            }
                        }} >
                            <IconButton onClick={() => setMobileMenuOpen(!mobileMenuOpen)} >
                                <MenuIcon />
                            </IconButton>
                        </Grid>

                        <Grid item sx={{
                            flexGrow: 1,
                            transition: "0.2s",
                            overflow: "hidden",
                            height: {
                                xs: mobileMenuOpen ? "auto" : "0vh",
                                sm: "auto",
                                md: "auto"
                            },
                            opacity: {
                                xs: mobileMenuOpen ? 1 : 0,
                                sm: 1,
                                md: 1
                            },
                        }}>
                            <Grid container



                                justifyContent={{
                                    xs: "center",
                                    lg: "flex-start"
                                }}
                                flexDirection="row"
                                alignItems="center"
                                sx={{
                                    height: "100%",

                                }}
                            >


                                <Box sx={{
                                    display: {
                                        xs: "none",
                                        lg: "inline-flex"
                                    }
                                }}>
                                    {
                                        data.role === "ROLE_EMPLOYEE"
                                            ?
                                            <CustomMenu
                                                title="Tanımlar"
                                                expandable
                                                link="/app/tanimlar"
                                                items={[
                                                    {
                                                        text: "Araçlar",
                                                        link: "/app/tanimlar/araclar"
                                                    },
                                                    {
                                                        text: "Güvenli Bölgeler",
                                                        link: "/app/tanimlar/guvenli-bolgeler"
                                                    },
                                                ]}
                                            />
                                            : <CustomMenu
                                                title="Tanımlar"
                                                expandable
                                                link="/app/tanimlar"
                                                items={[
                                                    {
                                                        text: "Araçlar",
                                                        link: "/app/tanimlar/araclar"
                                                    },
                                                    {
                                                        text: "Güvenli Bölgeler",
                                                        link: "/app/tanimlar/guvenli-bolgeler"
                                                    },

                                                    {
                                                        text: "Kullanıcılar",
                                                        link: "/app/tanimlar/kullanicilar"
                                                    },
                                                    {
                                                        text: "Gruplar",
                                                        link: "/app/tanimlar/gruplar"
                                                    },
                                                ]}
                                            />
                                    }

                                </Box>
                                <Box sx={{
                                    display: {
                                        xs: "inline-flex",
                                        lg: "none"
                                    }
                                }}>
                                    {
                                        data.role === "ROLE_EMPLOYEE"
                                            ?
                                            <CustomMenu
                                                icon={<FormatListBulletedRounded />}
                                                expandable
                                                link="/app/tanimlar"
                                                items={[
                                                    {
                                                        text: "Araçlar",
                                                        link: "/app/tanimlar/araclar"
                                                    },
                                                    {
                                                        text: "Güvenli Bölgeler",
                                                        link: "/app/tanimlar/guvenli-bolgeler"
                                                    },
                                                ]}
                                            />
                                            : <CustomMenu
                                                icon={<FormatListBulletedRounded />}
                                                expandable
                                                link="/app/tanimlar"
                                                items={[
                                                    {
                                                        text: "Araçlar",
                                                        link: "/app/tanimlar/araclar"
                                                    },
                                                    {
                                                        text: "Güvenli Bölgeler",
                                                        link: "/app/tanimlar/guvenli-bolgeler"
                                                    },

                                                    {
                                                        text: "Kullanıcılar",
                                                        link: "/app/tanimlar/kullanicilar"
                                                    },
                                                    {
                                                        text: "Gruplar",
                                                        link: "/app/tanimlar/gruplar"
                                                    },
                                                ]}
                                            />
                                    }

                                </Box>

                                {/* <CustomMenu
                                    title="Harita"
                                    expandable
                                    link="/app/islemler"
                                    items={[
                                        {
                                            text: "Harita",
                                            link: "/app/islemler/harita"
                                        },
                                        {
                                            text: "Takip",
                                            link: "/app/islemler/takip"
                                        },
                                    ]}
                                /> */}
                                <Box sx={{
                                    display: {
                                        xs: "none",
                                        lg: "inline-flex"
                                    }
                                }}>
                                    <CustomMenu
                                        title="Raporlar"
                                        expandable
                                        items={[
                                            {
                                                text: "Hareket Raporu",
                                                link: "/app/raporlar/hareket-raporu"
                                            },
                                            {
                                                text: "Alarmlar",
                                                link: "/app/raporlar/alarmlar"
                                            }
                                        ]}
                                    />
                                </Box>
                                <Box sx={{
                                    display: {
                                        xs: "inline-flex",
                                        lg: "none"
                                    }
                                }}>
                                    <CustomMenu
                                        icon={<img src="/assets/Vector.svg" />}
                                        expandable
                                        items={[
                                            {
                                                text: "Hareket Raporu",
                                                link: "/app/raporlar/hareket-raporu"
                                            },
                                            {
                                                text: "Alarmlar",
                                                link: "/app/raporlar/alarmlar"
                                            }
                                        ]}
                                    />
                                </Box>

                                <Box sx={{
                                    display: {
                                        xs: "none",
                                        lg: "inline-flex"

                                    },
                                }}>
                                    <CustomMenu
                                        title="Araç Takibi"
                                        link="/app/islemler/takip"
                                        blinking
                                    />
                                </Box>
                                <Box sx={{
                                    display: {
                                        xs: "inline-flex",
                                        lg: "none"
                                    }
                                }}>
                                    <CustomMenu
                                        icon={<img src="/assets/Frame.svg" />}
                                        link="/app/islemler/takip"
                                    />
                                </Box>

                                <Box sx={{
                                    display: {
                                        xs: "none",
                                        lg: "inline-flex"
                                    }
                                }}>
                                    <CustomMenu
                                        title="Rota Geçmişi"
                                        link="/app/islemler/gecmis_takip"
                                    />
                                </Box>

                                <Box sx={{
                                    display: {
                                        xs: "inline-flex",
                                        lg: "none"
                                    },

                                }}>
                                    <CustomMenu
                                        openIcon={<img src="/assets/delivery2.svg" />}
                                        closedIcon={<img src="/assets/delivery3.svg" />}
                                        link="/app/islemler/gecmis_takip"

                                    />
                                </Box>



                            </Grid>
                        </Grid>

                        <Grid item
                            sx={{
                                flexGrow: {
                                    xs: 1,
                                    md: 0
                                },
                                display: { xs: "none", md: "flex" }
                            }}
                        >
                            <Grid container
                                justifyContent={{
                                    xs: "space-between"
                                }}
                                alignItems="center"
                            >
                                <NotificationBar />
                                <CustomMenu
                                    title={displayName}
                                    icon={<AccountCircle />}
                                    expandable
                                    items={[
                                        {
                                            text: "Hesabım",
                                            link: "/app/kullanici/hesabim",
                                            icon: <AccountBox fontSize="small" />
                                        },
                                        {
                                            text: "Ayarlar",
                                            link: "/app/kullanici/ayarlar",
                                            icon: <Settings fontSize="small" />
                                        },
                                        {
                                            text: "Çıkış Yap",
                                            link: "/app/logout",
                                            icon: <ExitToApp fontSize="small" />,
                                        },

                                    ]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                </Toolbar>
            </AppBar >
            <Box component="main" >
                {children}
            </Box>
        </Box >
    )
}


export default CustomLayout